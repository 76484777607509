import { securedWrap } from '@mop/shared/utils/securedWrap';
import { ref, inject, nextTick } from 'vue';

export default function useUi2FormElement(props: any) {
  const register = inject('register', () => {}) as (component: any) => void;
  const unregister = inject('unregister', () => {}) as (component: any) => void;
  const validationErrorsRef = ref<string[]>([]);
  const loadingRef = ref(false);

  async function validate(): Promise<boolean> {
    await nextTick();
    validationErrorsRef.value = [];
    const rules: Function[] = props.rules as Function[];
    if (!rules || rules.length === 0) {
      return true;
    }
    loadingRef.value = true;
    validationErrorsRef.value = await rules.reduce(async (errorMessages: Promise<string[]>, rule: Function) => {
      // this sequence makes execution paralel, do not change the order of await calls
      const validationResult: string = await rule(props.modelValue);
      const previousValue: string[] = await errorMessages;
      if (typeof validationResult === 'string' && validationResult) {
        previousValue.push(validationResult);
      }
      return previousValue;
    }, Promise.resolve([]));
    loadingRef.value = false;
    return validationErrorsRef.value.length === 0;
  }

  return securedWrap({
    validationErrorsRef,
    register,
    unregister,
    validate,
    loadingRef,
  });
}
